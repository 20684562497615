input {
    border: 0;
    box-shadow: 0;
}

.input {
    border: 1px solid var(--light-gray);
    background: var(--base-gray);
    border-radius: var(--border-radius);
    font-size: var(--Small);
    padding: 1.7% 1%;
    transition: 0.2s ease-in;
}

.input_invalid {
    border: 1px solid #dd5f828c;
    transition: 0.2s ease-in;
    box-shadow: 0px 3px 8px #dd5f8269;
}

.labeled-input-container_col {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.labeled-input-container_row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.labeled-input-container_auto-width {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 4%;
}
.labeled-input-container_auto-width .input-container {
    width: 100%;
}
.labeled-input-container_auto-width .input {
    width: 100%;
}

.textarea {
    font-size: var(--Smal);
    border-radius: var(--border-radius);
    border: 1px solid var(--light-gray);
    background: var(--medium-gray);
}

.labeled-text-area-container {
    padding: 1%;
}

.labeled-text-area-container_col {
    display: flex;
    flex-direction: column;
}

.labeled-text-area-container_row {
    display: flex;
    flex-direction: row;
}

.input-toolbar-container {
    position: absolute;
    z-index: 3;
    min-width: 185px;
    max-width: 200px;
}

.input-toolbar {
    position: relative;
    display: block;
    z-index: 2;
    line-height: 1.25;
    font-weight: 500;
    background: none;
    color: var(--red);
    text-align: left;
    padding: 1% 0;
    width: 100%;
    font-size: 12px;
}

.input:focus {
    transition: 0.2s ease-out;
}

.input_disabled {
    background: var(--inactive);
    color: var(--medium-gray);
    cursor: not-allowed;
}

/*# sourceMappingURL=inputs.css.map */
