.pop-notifications-container-super {
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 2%;
    z-index: 5;
    pointer-events: none;
}

.pop-notifications-container {
    border-radius: var(--border-radius);
    opacity: 0;
    transition: 0.2s ease-in;
}

.pop-notifications-container_text {
    padding: 15px;
    box-shadow: 0px 23px 45px rgb(0 0 0 / 10%);
    background: var(--dark-gray);
    color: white;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.pop_notifications_warning {
    background: var(--red);
}

.pop-notifications-container_active {
    opacity: 1;
    transition: 0.2s ease-in;
    z-index: 999999;
    transform: translateY(0px);
}
.pop-notifications-icon svg {
    fill: var(--white);
}
.pop-notifications-title {
    padding: 0 5px;
}

@media only screen and (max-width: 1000px) {
    .pop-notifications-container_active {
        transform: translateY(50px);
    }
}

@media only screen and (max-width: 600px) {
    .pop-notifications-container_text {
        flex-direction: column;
        padding: 5px 20px 10px;
        text-align: center;
    }
}
/*# sourceMappingURL=pop-notifications.css.map */
