.add-payroll-modal-inputs-container {
    display: flex;
    padding: 2% 6% 1rem;
    flex-direction: column;
    align-items: center;
}

.add-payroll-modal-btn {
    padding: 5%;
    text-align: center;
}

.add-payroll-modal-inputs-container .data-input-container {
    padding: 0 1%;
}
