.super-container {
    display: grid;
    grid-template-columns: 17% 1fr;
    min-height: 900px;
}

.main-area {
    background: #f5f5f5;
}

/*# sourceMappingURL=super-container.css.map */
