button {
    background: 0;
    border: 0;
}

.btn {
    padding: 1% 0.5%;
    font-weight: 500;
    border-radius: var(--border-radius);
    line-height: 17px;
    min-height: 24px;
    transition: 0.2s ease-in;
}

.btn_primary {
    background: var(--dark-gray);
    color: var(--white);
}

.btn_primary:hover {
    transition: 0.2s ease-in;
}

.btn_primary_outline {
    color: var(--blue_primary);
    border: 1.5px solid var(--blue_primary);
}

.btn_success {
    background: var(--success);
    color: var(--white);
}

.btn_danger {
    color: var(--white);
    background: var(--danger);
}

.btn_full-width {
    width: 100%;
}

.btn_secondary {
    background: var(--base-gray);
    background: var(--inactive);
}

.btn_disabled {
    background: var(--inactive);
    cursor: not-allowed;
    color: var(--base-gray);
    transition: 0.2s;
}
.btn_blue {
    background: var(--sky-blue);
    color: var(--white);
}
.btn_small {
    padding: 0 !important;

    height: 44px;
    width: 160px;
}

.btn_large {
    height: 44px;
    width: 233px;
    padding: 0 !important;
}
