.default-list-placeholder-container {
    background: var(--base-gray);
    height: 25px;
    border-radius: var(--border-radius);
    width: 100%;
}

.default-list-placeholder-super {
    padding: 1% 0;
}

@media only screen and (max-width: 800px) {
    .default-list-placeholder-container {
        height: 90px;
    }
}
