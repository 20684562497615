/* .roll-exit-active {
    background: purple;
} */
.worker-profile-form-col1 .data-input-container,
.worker-profile-form-col2 .data-input-container {
    padding: 10px 15px;
}

.register-worker-row-button-container .btn_success {
    /* background: red; */
    /* min-width: 150px; */
    width: 160px;
    height: 44px;
    font-size: 15px;
    background: var(--dark-gray);
}
.bank-account-row-super {
    grid-template-columns: 1fr 40px;
    display: grid;
}
.register-worker-delete-index-container.register-worker-delete-index-container_bank-data {
    opacity: 1;
}
.register-worker-delete-index-container_bank-data {
    grid-column: 2;
    grid-row: 1/3;
}
.work-experience-form-container {
    padding-right: 5% !important;
}

.register-worker-loop-frament-super {
    display: grid;
    grid-template-columns: 1fr 30px;
}
.register-worker-delete-index-container {
    align-items: center;
    justify-items: center;
    align-self: center;
    transition: 0.12s;

    justify-self: center;
    display: grid;
    opacity: 1;
}

.register-worker-loop-frament-super {
    display: grid;
    grid-template-columns: 1fr 30px;
    padding: 0 0 30px;
}

.register-worker-loop-frament-super:hover .register-worker-delete-index-container {
    opacity: 1;
    transition: 0.12s;
}

.register-worker-delete-index-container svg {
    font-size: var(--XBig);
    fill: var(--inactive);
    transition: 0.12s;
}

.register-worker-delete-index-container:hover svg {
    fill: var(--red);
    transition: 0.12s;
}

.work-experience-section {
    padding: 1% 0;
}

.work-experience-form-super {
    padding: 1% 0;
    display: grid;
    grid-template-columns: 1fr 15%;
}

.work-experience-form-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 5%;
    gap: 0 5%;
    padding: 1.5% 0 0;
}
.work-experience-form-container .data-input-container {
    padding: 2.5% 2%;
}

.work-experience-form-container:first-child {
    padding: 0 0 1%;
}

.work-experience-form-container:not(:first-child) {
    padding: 1.5% 0 0;
}

.work-experience-button-container {
    padding: 0 1%;
    grid-column: 1;
}

.work-experience-item_both-columns {
    grid-column: 1/3;
    padding: 2.5% 1%;
}
.btn_primary.register-worker-add-new-payroll-btn {
    height: var(--btn-height);
    width: var(--small-btn-width);
}
.references-callback-button {
    padding: 2% 0 0;
}

.emergency-contact-inputs-container {
    padding: 0 1%;
}

.work-experience-add-file-btn-container {
    text-align: center;
}

.register-worker-control-buttons-container .control-button1 {
    font-size: 14px !important;
}
@media only screen and (max-width: 600px) {
    .work-experience-form-super {
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;
    }
}
@media only screen and (max-width: 550px) {
    /*  .work-experience-form-container{
       grid-template-columns: 1fr;
   } */
    .work-experience-form-container {
        grid-template-columns: 1fr;
    }

    .work-experience-item_both-columns {
        display: flex;
        flex-direction: column;
        grid-column: 1;
        min-height: 125px;
    }

    .input.work-experience-item_both-columns-input {
        height: 100%;
        min-height: 125px;
    }

    .work-experience-button-container {
        justify-self: center;
    }
}

.register-worker-main_personal-info {
    grid-template-columns: 1fr 1fr 1fr;
}

.register-worker-image {
    border-radius: 50%;
    /*   width: 80%; */
    grid-row: 1/3;
    /*   height: 55%; */
    border: 1px solid #e2e2e2e0;
    max-height: 300px;
    max-width: 300px;
    height: 91px;
    width: 91px;
}

.register-worker-main_personal-info-user-names {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
}

.reg-worker_personal-info-names-super {
    display: grid;
    grid-template-columns: auto auto;
}

.reg-worker_personal-info-names {
    display: grid;
    grid-template-columns: 4fr 1fr;
}

.reg-worker-personal-info-names-container {
    padding: 1% 0 0.5%;
}

.reg-worker-personal-info-buttons-container-super {
    justify-content: center;
    flex-direction: column;
}

.reg-worker-personal-info-buttons-container {
    width: 100%;
    grid-template-rows: 1fr 1fr 1fr;
}

.reg-worker-personal-info-btn {
    min-width: 125px;
    padding: 2% 5%;
}

.reg-worker-personal-info-inputs-container {
    padding: 0 2%;
}
.reg-worker-personal-info-inputs-container .data-input-container {
    padding: 0 1%;
}

.register-worker-personal-details {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    grid-template-rows: repeat(12, 1fr);
    align-items: center;
    grid-auto-flow: column;
    grid-column-gap: 2%;
}
/* .register-worker-personal-details > .labeled-checkbox-w-input-container_row > .checkbox-container {
    display: flex;
} */
.data-input-container {
    padding: 0 5%;
}

.register-worker-personal-info-col1-textarea-container {
    grid-row: 12/13;
    height: 100%;
}

.register-worker-personal-details-col2-labeled-quantity-input {
    /*  grid-column: 2;
    grid-row: 6/8; */
    grid-row: 11/13;
    height: 100%;
}

/* .register-worker-personal-info-col1-textarea {
    height: 30%;
} */

.reg-worker-personal-details-container {
    display: flex;
    flex-direction: column;
    padding: 0 4%;
}

.reg-worker-personal-details-container > .data-input-container {
    padding: 4% 1% 3%;
}

.register-worker-personal-details-col2-labeled-quantity-input-child {
    padding: 2% 5%;
}
@media only screen and (max-width: 865px) {
    .reg-worker_personal-info-names {
        grid-template-columns: 1fr;
    }

    .register-worker-personal-details {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(15, 1fr);
    }

    .register-worker-input-item_travel-available {
        grid-row: auto;
    }

    .register-worker-personal-details-col2-labeled-quantity-input {
        grid-column: auto;
        grid-row: 8/10;
    }

    .register-worker-personal-info-col1-textarea-container {
        grid-row: auto;
        grid-column: auto;

        height: 100%;
    }

    .register-worker-personal-info-col1-textarea {
        height: 70%;
    }
}
@media only screen and (max-width: 600px) {
    .register-worker-personal-details-super {
        padding: 0 0 5%;
    }

    .register-worker-main_personal-info-user-names {
        grid-template-columns: 1fr;
    }

    .register-worker-image {
        /*   width: auto; */
        /*   height: 75%; */
        grid-row: 1;
        width: 225px;
        height: 225px;
    }

    .reg-worker-personal-info-inputs-container {
        justify-content: center;
    }

    .register-worker-personal-details {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(30, 1fr);
    }

    .register-worker-input-item_travel-available {
        grid-row: auto;
    }

    .register-worker-personal-details-col2-labeled-quantity-input {
        grid-row: 19/21;
        grid-column: 1;
    }
    .reg-worker-personal-info-buttons-container-super {
        flex-direction: column;
    }
    .register-worker-personal-details-phone-info-container .labeled-select-container,
    .register-worker-personal-details-phone-info-container .input-with-select-item {
        padding: 0;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .register-worker-personal-details-phone-info-container .labeled-select-container {
        padding-bottom: 4%;
    }
}

.worker-profile-container-super {
    padding: 2% 0 2%;
}

.worker-profile-form-container {
    display: grid;
    grid-template-columns: 40% 1fr;
}
.worker-profile-invalid-date-warning {
    padding: 10px 0;
}
.register-worker-job-request-expected-package-input-container {
    grid-column: 1/3;
}
.worker-profile-form-container_edit-worker .worker-profile-form-col2 {
    display: none;
}

.worker-profile-form-container_edit-worker .worker-profile-form-col1 {
    grid-column: 1/3;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    display: grid;
}

.worker-profile-form-container_edit-worker
    .worker-profile-form-col1
    .data-input-container {
    flex-direction: column;
    align-items: flex-start;
}
/* Hide start date */
.worker-profile-form-container_edit-worker
    .worker-profile-form-col1
    .data-input-container:nth-child(2n + 3) {
    display: none;
}

@media only screen and (max-width: 555px) {
    .worker-profile-form-container_edit-worker .worker-profile-form-col1 {
        justify-items: center;
    }
}
@media only screen and (max-width: 465px) {
}
.register-worker-personal-details > .input-with-select-super-container {
    grid-template-columns: 40% 1fr;
}

.job-request-labeled-checkbox {
    width: 70%;
}

@media only screen and (max-width: 750px) {
    .worker-profile-form-container {
        grid-template-columns: 1fr;
        display: grid;
    }
    .worker-profile-form-col2 .flex-row-align-center-start_wrap {
        justify-content: flex-end;
    }
    .worker-profile-form-col2 .labeled-checkboxes-container-super {
        justify-content: space-between;
    }
    .register-worker-add-new-payroll-btn {
        min-height: 45px;
        padding: 2%;
    }

    .worker-profile-form-col1 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
    }
}

/* .register-worker-main {
    display: grid;
} */
.register-worker-main .main-area-header {
    display: block;
    padding: 1% 0;
}
.register-worker-main .main-area-header .main-area-title {
    padding: 1% 0;
}

.main-area-header .main-area-title {
    padding: 2% 0;
}

.register-worker-row {
    display: flex;
    flex-direction: column;
}

.register-worker-sub-row {
    padding: 0 1%;
}

.register-worker-input-container_row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.register-worker-input-container_row:not(:first-child),
.bank-account-row-super:not(:first-child) {
    padding: 2% 0 2%;
}

.register-worker-input-container_row > .data-input-container {
    padding: 4px 2.5%;
}

.register-worker-row-button-container {
    padding: 20px 1%;
}

@media only screen and (max-width: 700px) {
    .register-worker-input-container_row > .data-input-container {
        /*  padding: 0 25px; */
        /*  new*/
        padding: 1% 5%;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    .register-worker-row-button-container {
        text-align: center;
    }

    .register-worker-input-container_row {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
        align-items: center;
        justify-items: center;
    }
    /* 
    .register-worker-input-container_row .input-with-select-super-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        align-items: center;
        justify-items: center;
    } */
    .register-worker-input-container_row .input-with-select-super-container {
        /* grid-template-columns: 1fr 1fr; */
        grid-template-rows: 1fr 1fr;
        align-items: center;
        justify-items: center;
        display: grid;
        grid-template-columns: 1fr;
    }

    .register-worker-input-container_row
        .input-with-select-super-container
        .labeled-select-container_col {
        justify-content: space-between;
        width: 100%;
        padding-right: 0;
    }
    .register-worker-input-container_row .input-with-select-super-container {
        padding-top: 1%;
    }
    .register-worker-input-container_row
        > .data-input-container.labeled-text-area-container_col {
        flex-direction: column;
    }

    .register-worker-input-container_row
        .input-with-select-super-container
        .labeled-select-container_col {
        justify-content: space-between;
        width: 100%;
        padding-right: 0;
    }
    .register-worker-input-container_row
        .input-with-select-super-container
        .input-with-select-item {
        justify-content: space-between;
        flex-direction: row;
        padding-left: 0;
        width: 100%;
    }

    .register-worker-input-container_row .data-input-container .react-select-container {
        max-width: 150px;
    }

    .register-worker-row-button-container,
    .work-experience-button-container {
        padding: 20px 1%;
    }
    .register-worker-row-button-container .btn,
    .work-experience-button-container .btn {
        padding: 2%;
        min-width: 90px;
    }
    .register-worker-personal-details-phone-info-container {
        /* align-items: center; */
        /* justify-content: center; */
        grid-template-columns: 1fr !important;
        /* grid-template-rows: 1fr 1fr; */
    }

    /*  .labeled-select-container_col {
        width: 100%;
        flex-direction: revert;
        justify-content: space-between;
        padding: 0;
    } */
}
@media only screen and (max-width: 750px) {
    .register-worker-loop-frament-super {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
    .register-worker-loop-frament-super .register-worker-delete-index-container {
        padding-top: 20px;
    }

    .bank-account-row-super {
        grid-template-columns: 1fr;
        display: grid;
    }
    .register-worker-delete-index-container.register-worker-delete-index-container_bank-data {
        grid-row: 3;
    }

    .register-worker-delete-index-container {
        grid-column: 1;
        grid-row: 2;
    }

    .main-area-subtitle {
        padding: 2.5%;
    }

    .register-worker-input-container_row:not(:first-child) {
        padding: 4% 0;
    }
}

@media only screen and (max-width: 465px) {
    .register-worker-input-container_row {
        padding: 8px;
        justify-items: center;
    }

    .register-worker-input-container_row:not(:first-child) {
        padding: 5% 0;
    }
}

@media screen and (min-width: 1600px) {
    /*   .register-worker-input-container_row {
      background: purple;
  } */
    .register-worker-input-container_row {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .register-worker-input-container_row:not(:first-child) {
        padding: 2% 0;
    }

    .labeled-input-container_col.data-input-container {
        padding: 3% 2.5% 0;
    }
}

/*# sourceMappingURL=register-worker.css.map */
