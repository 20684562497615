.modal-container-super {
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
}

/* .super-container_show-modal {
  height: 100vh;
  overflow-y: hidden; } */
/*   .super-container_show-modal */

.modal-super {
    background: rgba(0, 0, 0, 0.25);
    /*   background: #f5f5f5d9; */
    height: 100%;
    width: 100%;
    /* position: absolute; */
    z-index: 2;
    opacity: 1;
    /*  display: grid;
  align-items: center;
  justify-items: center; */
}

.modal-container-super {
    transition: 0.2s ease-in;
    opacity: 1;
    pointer-events: all;
    z-index: 99;
}

.modal-button {
    font-size: 18px;
    height: var(--btn-height);
    width: var(--small-btn-width);
}
.modal-container {
    box-shadow: 0px 23px 45px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    background: var(--white);
    padding: 2% 4%;
}

.modal-title {
    font-size: var(--Big);
    padding: 4% 0;
    text-align: center;
    color: var(--input-login-title-gray);
}

/* .modal-items-container {
    @include flex-col-align-center;
} */

.modal-item-btn-container {
    padding: 3% 0;
    width: 100%;
}
.modal-item-btn-container .btn {
    font-size: 24px;
    padding: 5%;
    font-weight: 500;
}

/*# sourceMappingURL=modal.css.map */
.modal-button-container {
    padding: 5% 0 0;
}

.close-modal-icon {
    font-family: sans-serif;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    flex-direction: row-reverse;
    font-size: var(--Small);
}

.modal-button {
    padding: 5%;
}

.modal-register-worker-local-data-buttons-container {
    grid-template-columns: 1fr 1fr;
}

.modal-add-schedule-btn {
    width: 140px !important;
    height: 40px !important;
}

.modal-register-worker-local-data-button {
    padding: 3%;
    width: 100%;
    text-align: center;
}
.modal-register-worker-local-data-inner-btn {
    width: 85%;
    height: var(--btn-height);
}
.modal-btn {
    height: var(--btn-height);
    width: var(--small-btn-width);
}
.add-assistance-modal-text-container {
    text-align: center;
    padding: 1% 0 0;
    line-height: 1.2;
}

.close-modal-icon-outside {
    position: absolute;
    right: 30px;
    top: 8%;
    width: 50px;
}

.close-modal-icon-outside svg {
    font-size: 36px;
    fill: var(--dark-gray);
}

@media only screen and (max-width: 600px) {
    .modal-register-worker-local-data-buttons-container.grid-center {
        grid-template-columns: 1fr;
    }

    .modal-container {
        /* padding: 2%; */
        width: 75%;
    }
}
