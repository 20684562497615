.schedule-picker-container {
    display: grid;
    grid-template-columns: 1fr;
}
.schedule-picker-hours-inputs-container-super {
    display: none;
}

.weekDays-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.worker-schedule-input-item {
    padding: 0 5px;
}
.schedule-picker-hours-inputs-item {
    padding: 1%;
}

.schedule-picker-hours-inputs-item-title {
    font-weight: 500;
}

.schedule-picker-hours-inputs-hours {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.schedule-picker-rest {
    grid-column: 1/3;
}
.hours-inputs-item-error-label {
    max-width: 300px;
}
/*# 

.schedule-picker-hours-inputs-item_valid {
    border-left: 3px solid var(--success);
}
.schedule-picker-hours-inputs-item_invalid {
    border-left: 3px solid var(--red);
}
*/
.schedule-picker-button-container {
    width: 75%;
    padding: 3%;
}

.schedule-picker-button {
    font-size: var(--Normal);
    padding: 1.5% 6%;
}

.schedule-picker-rest-super {
}

.add-hours-btn-container {
    text-align: center;
    padding: 5% 0 0;
}
.add-hours-btn {
    fill: var(--white);
    padding: 1% 5%;
    font-size: var(--Big);
}

@media only screen and (max-width: 950px) {
    .schedule-picker-container {
        grid-template-columns: 1fr;
    }
    .schedule-picker-rest-super {
        grid-column: 1;
    }
}

@media only screen and (max-width: 750px) {
    .weekDays-items {
        grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    }
}

@media only screen and (max-width: 450px) {
    .schedule-picker-hours-inputs-hours {
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
    }
}

@media only screen and (max-width: 415px) {
    .weekDays-items {
        align-items: center;
        justify-items: center;
    }
}
