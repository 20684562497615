.navbar-container {
    display: grid;
    padding: 30px 3.8%;

    align-items: center;
    grid-template-columns: 1fr;
}

.navbar {
    display: grid;
    grid-template-columns: 1fr 10%;
    padding: 1.5% 1.5% 1.5%;
    gap: 0 4%;
}
/* 
.navbar-search-input-container {
    background: white;
    display: flex;
    align-items: center;
    padding: 1% 2%;
    border-radius: 15px;
} */

.navbar-search-input-container svg {
    fill: var(--light-gray);
}

.navbar-search-input {
    background: none;
    border: 0;
    width: 100%;
}

.navbar-icons-container {
    justify-self: end;
    fill: var(--light-gray);
    justify-content: space-evenly;
    font-size: var(--Big);
}

.navbar-icon-notification-container {
    display: grid;
    grid-template-rows: 0 1fr;
}

.navbar-icon-notification_active {
    background: var(--red);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    z-index: 8;
    justify-self: end;
}

.navbar-user-img {
    width: 24px;
    height: 24px;
    background: black;
    border-radius: 50%;
}

.navbar-user-img-img {
    width: 100%;
    height: 100%;
}

.navbar-user-img-super {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.navbar-user-img-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.menu-icon {
    display: none;
}

.navbar-search-input-container {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .navbar {
        grid-template-columns: 1fr 115px;
        padding: 8px;
    }

    .navbar-container {
        grid-template-columns: 10% 1fr;
        background: var(--dark-gray);
        position: fixed;
        width: 100%;
        z-index: 8;
        padding: 8px;
    }

    .navbar-icon {
        display: grid;
        height: 24px;
    }
    .navbar-icons-container {
        justify-self: end;
        justify-content: space-between;
        font-size: var(--Medium);
    }

    .menu-icon {
        text-align: center;
        display: block;
    }
}
@media only screen and (max-width: 411px) {
    .navbar-search-input-container {
        font-size: var(--Small);
    }
}
@media only screen and (max-width: 500px) {
    /*    .navbar-icons-container {
      display: none;
  } */
}
@media only screen and (min-width: 1400px) {
    .navbar-search-input {
        font-size: var(--xbs_Small);
    }

    .navbar-user-img {
        height: 23px;
        width: 23px;
    }
}

/*# sourceMappingURL=navbar-style.css.map */
