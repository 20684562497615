.request-error {
    color: var(--icon-gray);
    align-self: center;
    max-height: 150px;
}
.request-error-btn svg {
    fill: white;
    font-size: var(--Big);
}

.request-error-icon-container svg {
    font-size: var(--XBig);
    fill: var(--icon-gray);
}
.request-error-main-text {
    color: var(--icon-gray);
    padding: 1% 0 0;
}
.request-error-code-text bold {
    color: inherit;
}
.request-error-btn {
    border-radius: 5px;
    height: var(--btn-height);
    /*    width: var(--small-btn-width); */
    width: 55px;
}

.request-error-main-paragraph {
    text-align: center;
    line-height: 1.2;

    max-width: 65ch;
}
.request-error-btn > svg {
    font-size: var(--Big);
}

.reques-error-btn-container {
    padding: 1.5% 0;
}
