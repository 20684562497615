.control-buttons-container-super {
    padding: 3%;

    padding: 20px 3%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.control-buttons-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
}

.control-buttons-container_two-buttons {
    grid-template-columns: repeat(2, 1fr);
}

.control-buttons-container .btn {
    font-size: var(--Normal);
}

.control-button {
    color: white;
    padding: 0 20px;
}

.control-button .btn_disabled {
    background: #e0e0e0;
}
@media screen and (min-width: 1400px) {
    .control-button .btn {
        width: 225px;
        padding: 8%;
    }
}

/*# sourceMappingURL=control-buttons.css.map 
.control-button .btn {
    width: 100%;
    padding: 7% 0;
    width: 185px;
}
*/
