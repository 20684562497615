@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

* {
    box-sizing: border-box;
}

.super-container {
    display: grid;
    grid-template-columns: 16.5% 1fr;
    min-height: 100vh;
}

.main-area {
    background: var(--base-gray);
}

@media only screen and (max-width: 1000px) {
    .main-area {
        grid-column: 1/3;
    }

    .main-area-section .main-card {
        padding-top: 45px;
    }
}

h1 {
    font-size: var(--Big);
}

h2 {
    font-size: var(--Medium);
}

h3 {
    font-size: var(--Medium-Small);
}

h4 {
    font-size: var(--Normal);
}

h5 {
    font-size: var(--Small-Big);
}

h6,
label,
button,
a,
p,
.small .input {
    font-size: var(--Small);
}

.xSmall {
    font-size: var(--XSmall);
}
/* .success-text {
  color: var(--success);
} */
.sucess {
    /*     color: var(--sucess); */
    color: var(--success) !important;
}

@media screen and (min-width: 1600px) {
    h1 {
        font-size: var(--xbs_Big) !important;
    }

    h2 {
        font-size: var(--xbs_Medium) !important;
    }

    h3 {
        font-size: var(--xbs_Medium-Small) !important;
    }

    h4 {
        font-size: var(--xbs_Normal) !important;
    }

    h5 {
        font-size: var(--xbs_Small-Big) !important;
    }

    h6,
    label,
    button,
    a,
    p,
    .input {
        font-size: var(--xbs_Small) !important;
    }
}
label {
    color: var(--dark-gray);
    padding: 7px 5px;
    display: block;
    line-height: 19px;
}

mark {
    background: none;
}

button {
    font-size: var(--Small);
}

a {
    color: var(--input-login-gray);
    text-decoration: underline;
    display: block;
}

.uppercase {
    text-transform: uppercase;
}

.full-height {
    height: 100%;
}

.main-area-title {
    font-weight: 500;
}

.main-card {
    padding: 0 3.8% 6%;
}

.primary-card {
    padding: 1% 2%;
    /* margin: 1%;  ??? */
    background: var(--white);
    border-radius: 15px;
}

.main-area-subtitle {
    font-weight: 600;
    padding: 1% 0;
}

.main-area-header {
    align-items: center;
    padding: 2% 0;
}
.main-area-header .main-area-title {
    padding: 0;
}

.main-area-header-link {
    color: var(--var(--icon-gray));
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
button,
a,
input,
span,
text,
.react-select-container {
    font-family: "Montserrat", sans-serif;
}

.lazy-loader-container {
    /*  background: rgba(0, 0, 0, 0.35);*/
    /*    background: #f5f5f5e0;;*/

    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    opacity: 1;
}

.success-text {
    color: var(--sucess);
}

.invalid-text {
    color: var(--red);
}

.app-loading {
    height: 100vh;
    background: var(--base-gray);
}

@media only screen and (max-width: 650px) {
    .home-payments-chart-title-container {
        width: 100%;
    }
}

.my-node-appear {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
}

.my-node-appear-active {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
}

.roll-appear {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
}

.roll-enter {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    transition: 0.25s;
}

.roll-enter-done {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    transition: 0.25s;
}

.roll-exit {
    opacity: 0.8;
    transform: translateY(-30px);
    transition: 0.25s;
}

.roll-exit-active {
    opacity: 0;
    /*    transform: translateY(-30);
    transition: 0.25s; */
}

.link {
    color: var(--input-login-gray);
    text-decoration: underline;
    display: block;
}

/*# sourceMappingURL=main.css.map */
.bold {
    font-weight: 500;
}

.placeholder-text_medium {
    height: 15px;
    border-radius: 3px;
    /*     background: var(--placeholder);
 */
    background: linear-gradient(270deg, #f5f5f5, #dedede);
    background-size: 400% 400%;

    -webkit-animation: gray-gradient-transition 11s ease infinite;
    -moz-animation: gray-gradient-transition 11s ease infinite;
    animation: gray-gradient-transition 11s ease infinite;
}
.placeholder-text_small {
    border-radius: 3px;

    height: 10px;
    /*    background: var(--placeholder); */

    background: linear-gradient(270deg, #f5f5f5, #dedede);
    background-size: 400% 400%;

    -webkit-animation: gray-gradient-transition 11s ease infinite;
    -moz-animation: gray-gradient-transition 11s ease infinite;
    animation: gray-gradient-transition 11s ease infinite;
}

.text-overflow-hidden {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.no-opacity {
    opacity: 0;
}
.control-button .btn {
    /*  padding: 7% 0; */
    width: 160px;
    height: 44px;
}
.simplebar-scrollbar:before {
    background: #bdbdbd !important;
}

@media only screen and (max-width: 600px) {
    .control-buttons-container {
        width: 95%;
    }

    .control-button {
        padding: 0 5px;
    }
}
.control-buttons-container-super {
    padding: 20px 3% 0;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .control-buttons-container {
        width: 50%;
        grid-template-columns: 1fr;
        justify-items: center;
    }
    .control-button {
        padding: 0.75rem 0;
        /* width: 50%; */
    }
}
