/* .sidebar-company-img-container:hover .sidebar-company-img {
    transform: scale(1.5);
    transition: 0.12s;
}
 */
.slide-out-left {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.sidebar-container-super {
    color: var(--icon-gray);
    background: var(--dark-gray);
}

.sidebar-container {
    color: var(--icon-gray);
    background: var(--dark-gray);
    display: grid;
    grid-template-rows: auto auto 1fr;
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 16.5%;
    /* max-width: 15%; */
}
.sidebar-company-img-input {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    opacity: 0;
    cursor: pointer;
}
.sidebar-item-icon svg {
    fill: var(--icon-gray);
    font-size: var(--Big);
}

.sidebar-items-container {
    /*  @include flex-col-align-space-start;
  display: flex;
  flex-direction: column;
  align-self: flex-start; */
    width: 100%;
}

.sidebar-item {
    display: grid;
    grid-template-columns: auto 1fr;
    color: inherit;
    width: 100%;
    transition: 0.2s;
    padding: 3%;
    align-items: center;
}

.sidebar-item-icon {
    fill: var(--icon-gray);
    padding: 5px;
}
.sidebar-item_selected .sidebar-item-icon svg {
    fill: var(--white);
}
.sidebar-item_selected:hover .sidebar-item-icon svg {
    fill: inherit;
}

.sidebar-log-out-container {
    display: flex;
    flex-direction: column-reverse;
}

.sidebar-log-out {
    padding: 5% 0 58px;
    display: grid;
    cursor: pointer;
    text-align: center;
    align-items: center;
    justify-content: center;
    /* grid-template-rows: auto auto; */
    grid-template-columns: auto auto;
}

.sidebar-log-out-link {
    text-decoration: none;
}

.sidebar-company-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10%;
    color: var(--light-gray);
    /* justify-content: center; */
}

.sidebar-company-name-container {
    padding: 5%;
    text-align: center;
    font-weight: 600;
}

.sidebar-company-img {
    border-radius: 50%;
}

.sidebar-img {
    width: 100%;
}

.sidebar-item-link,
.sidebar-collapsible-title {
    font-weight: 600;
    text-decoration: none;
    transition: 0.2s ease-in;
    font-size: var(--Small);
    align-self: center;
}

.sidebar-item span,
.sidebar-collapsible-title a {
    text-decoration: none;
    padding: 5% 20px 5% 0;
    font-weight: 600;
    transition: 0.2s ease-in;
}

.sidebar-item_selected a {
    color: var(--white);
}

.sidebar-item-link-hidden {
    padding: 5px;
    /* pointer-events: none; */
    position: absolute;
    opacity: 0;
}

.sidebar-item-link_selected,
.sidebar-collapsible-title.active {
    color: var(--white);
    transition: 0.2s ease-in;
}

.sidebar-item_single:hover {
    background: var(--white);
    color: var(--icon-gray);
}

.sidebar-item-icon_expenses path {
    fill: var(--icon-gray);
}

.sidebar-collapsible-title.active .sidebar-item-icon_expenses path,
.sidebar-collapsible-title.active .sidebar-item-icon svg {
    fill: var(--white);
}

.sidebar-item-collapsible-children {
    transition: 0.2s ease-in;
    opacity: 0;
}

.sidebar-item-collapsible-children_collapsed {
    transition: 0.2s ease-in;
}

.sidebar-item-collapsible-title-container {
    display: flex;
    align-items: center;
}

.sidebar-item-collapsible-container {
    width: 100%;

    /*   grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto; */
}

.sidebar-expand {
    /*  justify-self: end; */
    padding: 10px;
    cursor: pointer;
}

.sidebar-item-collapsible {
    grid-row: 2;
    text-align: center;
    grid-column: 1/4;
}

.sidebar-item-collapsible a {
    padding: 3% 10%;
    text-align: left;
}

.expand-icon_more svg {
    transform: rotate(180deg);
    transition: 0.2s;
    pointer-events: none;
}

.expand-icon_less svg {
    transform: rotate(0deg);
    transition: 0.2s;
    pointer-events: none;
}

.sidebar-logout-icon,
.sidebar-log-out-link {
    padding: 0 2%;
}

.sidebar-item_selected {
    color: white;
}
.sidebar-item-collapsible-item-title-container {
    display: grid;
    grid-template-columns: 1fr auto;
}

.sidebar-icon-expand svg {
    stroke: white;
}

.sidebar-item-icon.active svg {
    fill: var(--white);
}

.sidebar-collapsible-item-link:hover,
.sidebar-item-collapsible-item-title-container:hover {
    transition: 0.2s ease-in;
    background: var(--white);
    color: var(--icon-gray);
}

.sidebar-item-collapsible-item-title-container:hover .sidebar-collapsible-title.active {
    color: var(--icon-gray);
}

.sidebar-item-collapsible-item-title-container:hover .sidebar-item-icon.active svg {
    fill: var(--icon-gray);
}

.sidebar-item-collapsible-item-title-container:hover .sidebar-icon-expand svg {
    stroke: var(--icon-gray);
}

@media only screen and (max-width: 1000px) {
    .sidebar-company-container {
        padding: 2%;
    }

    .sidebar-company-name-container {
        padding: 3%;
    }

    .sidebar-container-super {
        transition: 0.2s ease-in;
        position: absolute;
        z-index: -1;
        transition-delay: 0.2s;
        opacity: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .sidebar-container {
        /*      -webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both; */
        /*       -webkit-animation: scale-out-left 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: scale-out-left 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both; */
        -webkit-animation: slide-out-left 1.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
        animation: slide-out-left 1.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
        min-height: 100%;

        position: absolute;
    }

    .sidebar-container-super_active {
        transition: 0.2s ease-in;
        z-index: 9;
        width: 100%;
        height: 100%;
        pointer-events: all;
        transition-delay: 0.12s;
        background: rgba(14, 9, 9, 0.2);
        position: fixed;
    }

    .sidebar-container.sidebar-mobile_open {
        /* background: gold; */
        z-index: 9;
        min-width: 250px;
        /*         padding: 2%;*/
        min-height: 100%;
        max-width: 50vw;
        opacity: 1;
        -webkit-animation: slide-in-left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) both;
        animation: slide-in-left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) both;
        overflow: hidden;
    }
}
@media screen and (min-width: 1400px) {
    .sidebar-item-icon svg {
        font-size: var(--xbs_Big);
    }

    .sidebar-expand {
        font-size: var(--xbs_Small);
    }
}

/*# sourceMappingURL=sidebar-style.css.map */
