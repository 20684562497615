.payments-item-container {
    padding: 1% 0; /* fix?*/
    line-height: 15px;
}
.payments-dashboard-inner {
    grid-column: 1/3;
    min-height: 230px;
}
.payments-item,
.payments-item_toggle {
    background: var(--base-gray);
    /*   padding: 0.45% 2%; */
    border-radius: var(--border-radius);
    grid-template-columns: 4fr 3fr 2fr 2fr 2fr 1fr 40px;
    align-items: center;
    text-align: center;
    line-height: 20px;
    display: grid;
}

.payment-item-icon {
    padding: 0 5px;
    font-size: var(--Big);
}

.recent-payments-item-text,
.payments-item-link a {
    font-size: var(--XSmall);
    line-height: 1.3;
    padding: 0.5px 0;
}

.btn.btn_primary.payments-item-button {
    padding: 11px 2.5rem;
    font-size: 18px;
}

.payments-item-inputs-container .data-input-container {
    padding: 3%;
}

.payments-item_toggle {
    grid-template-columns: repeat(7, 1fr);
}
.payment-item-icon {
    transition: 0.2s ease-in;
}
.payment-item-icon_red {
    /*  fill: var(--danger); */
    fill: var(--success);
}
.payment-item-icon_red.payment-item-icon_open {
    transform: rotate(45deg);
    /*  fill: var(--green-success);
    fill: var(--red); */
    fill: var(--danger);
}
.payment-item-icon_success {
    fill: var(--success);
}

.payments-table-header {
    display: grid;
    text-align: center;
    align-items: center;
    padding: 2%;
    grid-template-columns: 4fr 2fr 2fr 2fr 2fr 1fr auto;
}

.payments-table-header-item_hidden {
    opacity: 0;
    font-size: 12px;
    pointer-events: none;
}

.payments-table {
    padding: 1% 1.75%;
}

.payments-table-header-item {
    color: #000;
    font-weight: 500;
}

.payments-main-header {
    padding: 1% 3%;
}

.payments-footer-super {
    padding: 1%;
}

.payments-main-header-date {
    color: var(--icon-gray);
    font-size: var(--Small);
    font-weight: 500;
    text-decoration: underline;
}

.payments-selects-container {
    padding: 1%;
    flex-wrap: wrap;
}
.payments-selects-container .data-input-container {
    padding: 1%;
}
.payment-control-buttons-container-super {
    transition: 0.6s ease;
}

.payment-control-buttons-container-super .control-button .btn_default {
    color: var(--white);
}

.payment-control-buttons-container-super .control-button:nth-child(2) .btn {
    background: var(--sky-blue);
}
.payment-control-buttons-container-super .control-button:nth-child(3) .btn {
    background: var(--success);
}

.recent-payments-items-container .payments-item-container {
    padding: 1% 0;
}

.recent-payments-items-container .payments-table-header {
    padding: 0;
    grid-template-columns: 4fr 3fr 2fr 2fr 2fr 1fr 40px;
}

@media only screen and (max-width: 950px) {
    .table-container-super {
        display: grid;
    }

    /*   .payments-table-container {
        overflow-x: scroll;
        width: 100%;
    }

    .payments-table {
        min-width: 830px;
    }
 */
    .payments-footer-super {
        padding: 0 5%;
    }
}
@media only screen and (max-width: 800px) {
    .payments-item.recent-payments-item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 2% 0;
    }
}

@media only screen and (max-width: 500px) {
    .payments-footer-container {
        padding: 5%;
    }

    .payments-item h6,
    .payments-item_toggle h6 {
        padding: 0 5%;
    }
}
