.labeled-select-container_col {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.labeled-select-container_row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.react-select-container {
  min-width: 162px;
  font-size: var(--Small);
  max-width: 190px;
}
.react-select-container .react-select__control {
  background: var(--base-gray);
  border: 1px solid var(--light-gray);
  border-radius: var(--border-radius);
  min-height: 24px;
  height: 24px;
}
.react-select-container .react-select__value-container {
  padding: 0;
}
.react-select-container .react-select__indicator-separator {
  display: none;
}
.react-select-container .react-select__indicators {
  height: 100%;
}
.react-select-container .react-select__indicator {
  padding: 2px 3px;
}
.react-select-container .react-select__placeholder {
  color: var(--dark-gray);
  font-weight: 500;
  padding: 0 4%;
}
.react-select__menu {
  z-index: 4 !important;
  min-width: 200px;
}

.select_disabled .react-select-container .react-select__control {
  background: var(--inactive);
  color: var(--medium-gray);
  cursor: not-allowed;
}

.input-container-invalid .react-select__control {
  border: 1px solid #dd5f828c !important;
  transition: 0.2s ease-in;
  box-shadow: 0px 3px 8px #dd5f8269;
}
@media screen and (min-width: 1400px) {
  .react-select-container {
    height: auto;
  }
  .react-select-container .react-select__control {
    font-size: var(--xbs_Small);
    height: auto;
  }
  .react-select-container .react-select__indicators {
    height: auto;
  }
  .react-select-container .react-select__option {
    font-size: var(--xbs_Small);
  }
}

/*# sourceMappingURL=select.css.map */
