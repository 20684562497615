.slide-in-bottom {
    -webkit-animation: slide-in-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-fwd-center {
    -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-in-center_fast {
    -webkit-animation: scale-in-center 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-center 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-in-center_delay {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s
        both;
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s both;
}

@-webkit-keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}
@keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center_delay {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes scale-in-center_delay {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
.slide-out-left {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}
@keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}
/* ----------------------------------------------
 * Generated by Animista on 2020-7-23 15:12:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
.slide-in-left {
    -webkit-animation: slide-in-left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: slide-in-left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes scale-out-left {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
}
@keyframes scale-out-left {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
}
.fade-in {
    -webkit-animation: fade-in 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.slide-bottom {
    -webkit-animation: slide-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
        opacity: 1;
    }
}
@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
        opacity: 1;
    }
}

.gray-gradient-transition {
    background: linear-gradient(270deg, #f5f5f5, #dedede);
    background-size: 400% 400%;

    -webkit-animation: gray-gradient-transition 11s ease infinite;
    -moz-animation: gray-gradient-transition 11s ease infinite;
    animation: gray-gradient-transition 11s ease infinite;
}

@-webkit-keyframes gray-gradient-transition {
    0% {
        background-position: 99% 0%;
    }
    50% {
        background-position: 2% 100%;
    }
    100% {
        background-position: 99% 0%;
    }
}
@-moz-keyframes gray-gradient-transition {
    0% {
        background-position: 99% 0%;
    }
    50% {
        background-position: 2% 100%;
    }
    100% {
        background-position: 99% 0%;
    }
}
@keyframes gray-gradient-transition {
    0% {
        background-position: 99% 0%;
    }
    50% {
        background-position: 2% 100%;
    }
    100% {
        background-position: 99% 0%;
    }
}
