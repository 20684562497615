.center-card-container-super {
    display: grid;
    align-items: center;
    justify-content: center;
    background: var(--base-gray);
    min-height: 100vh;
    grid-template-columns: 1fr;
}

.center-card-return-arrow {
    position: absolute;
    left: 4.93%;
    top: 5.47%;
}

.center-card-pad {
    padding: 0 32%;
}

.center-card-container {
    background: var(--white);
    padding: 10%;
    border-radius: 30px;
    box-shadow: 0px 23px 45px rgba(0, 0, 0, 0.1);
}
.center-card-container .lazy-loader-container {
    transition: 0.1s;
    background: var(--base-gray);
}
.center-card-container .center-card-item {
    padding: 2% 0;
}
.center-card-container .center-card-link {
    color: var(--input-login-gray);
    font-size: var(--Small);
    text-decoration: underline;
    text-align: left;
    padding: 2% 0 0;
    display: block;
}
.center-card-title {
    padding: 0 0 3%;
}
.input-login {
    padding: 0;
}
.center-card-container .center-card-title,
.center-card-container .biz-select-title {
    font-size: var(--Big);
    text-align: center;
    color: var(--input-login-gray);
}
.center-card-container .center-card-subtitle {
    color: var(--input-login-subtitle-gray);
    font-size: var(--Small);
    text-align: center;
    padding: 5% 0 3%;
    line-height: 17px;
}
.center-card-container .input-login label {
    color: var(--input-login-gray);
}
.center-card-container .input-login .input {
    border: 0;
    border-radius: 15px;
    /*  font-size: var(--Medium); */
    font-size: 26px !important;
    padding: 2%;
    width: 100%;
}
.center-card-container .btn-center-card {
    border-radius: 12px;
    text-transform: uppercase;
    margin: 5% 0;
    background: var(--success);
    padding: 5%;
    font-weight: 600;
}

.auth0-container-title {
    font-size: var(--Small);
    color: var(--input-login-gray);
    text-align: center;
}

.auth0-icons-container {
    padding: 2% 2% 0;
}

.auth0-icon {
    border: 1px solid var(--input-login-gray);
    border-radius: 50px;
    margin: 2% 3%;
    padding: 7px;
    display: flex;
}

@media only screen and (max-width: 1050px) {
    .center-card-pad {
        padding: 0 27%;
    }
}
@media only screen and (max-width: 750px) {
    .center-card-pad {
        padding: 0 20%;
    }
}
@media only screen and (max-width: 750px) {
    .center-card-pad {
        padding: 0 20%;
    }
}
@media only screen and (max-width: 650px) {
    .center-card-pad {
        padding: 0 15%;
    }
}
@media only screen and (max-width: 600px) {
    .center-card-pad {
        padding: 0 15%;
    }
    .biz-select-container {
        padding: 5% 10%;
    }
}
@media only screen and (max-width: 475px) {
    .center-card-pad {
        padding: 0 10%;
    }
}
@media only screen and (max-width: 475px) {
    .center-card-container-super {
        background: var(--white);
        min-height: 100vh;
    }

    .center-card-pad {
        padding: 0;
    }

    .center-card-container {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: 0 0 0;
    }

    .center-card-return-arrow {
        position: absolute;
        left: 8.93%;
        top: 1.47%;
    }
}
/* @media screen and (min-width: 1600px) {
    .input-login {
        background: red;
        font-size: 26px !important;
    }
}
 */
