.pop-legal-notifications-super {
    position: fixed;
    bottom: 0px;
    background: gold;
    pointer-events: none;
    opacity: 0;
    height: auto;
    width: 100%;
    padding: 1.5%;
    z-index: 9;
    transition: 0.15s;
    background: var(--red);
    color: white;
    background: var(--blue_info);
}

.pop-legal-notifications-container {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
}

.pop-legal-notifications-title {
    padding: 0 5px 0 0;
}
.pop-legal-notifications-super_active {
    opacity: 1;
    pointer-events: all;
}

.pop-legal-notifications-icon-container {
    height: auto;
    width: auto;
    margin: 0 1% 0 0;
}

.pop-legal-notifications-icon-container svg {
    /* background: red; */

    fill: white;
}
