/* font-size: var(--Small);
font-size: var(--Big);
var(--white);
 var(--input-login-gray); 
 */
:root {
    --base-gray: #f5f5f5;
    --light-gray: #bdbdbd;
    --medium-gray: #e6e6e6;
    --dark-gray: #1f1f33;
    --inactive: #e0e0e0;
    --white: #ffffff;

    --inactive-text: var(--medium-gray);
    --icon-gray: #828282;
    --green: #38b25d;
    --red: #dd5f82;
    --danger: #fe786c;
    --yellow: #f49d4c;
    --blue_primary: #246f96;
    --blue_info: #3ba2b1;
    --sky-blue: #96a0ee;
    --deep-blue: #7877f5;
    --success: #2c41dd;
    --border-radius: 7.5px;
    --border-radius_secondary: 15px;

    --input-login-gray: #757575;
    --input-login-title-gray: #4f4f4f;
    --input-login-subtitle-gray: #4d4d4d;

    --XXBig: 36px;
    --XBig: 32px;
    --Big: 24px;
    --Medium: 22px;
    --Medium-Small: 20px;
    --Normal: 18px;
    --Small-Big: 16px;
    --Small: 14px;
    --XSmall: 12px;

    --big-line-height: 1.75;
    --medium-line-height: 1.5;
    --small-line-height: 1.25;

    --nblue: #2c41dd;
    --norange: #fe786c;
    --nred: #dd5f82;
    --npurple: #96a0ee;
    --ndark-gray: #1f1f33;
    --green-success: #33d9b2;
    --placeholder: var(--base-gray);

    --btn-height: 44px;
    --small-btn-width: 160px;
    --big-btn-width: 233px;

    /*  sizes * 1.2*/
    /*     --xbs_XXBig: 43px;
    --xbs_xbig: 38px;
    --xbs_Big: 28px;
    --xbs_Medium: 26px;
    --xbs_Medium-Small: 24px;
    --xbs_Normal: 21px;
    --xbs_Small-Big: 19px;
    --xbs_Small: 16px;
    --xbs_XSmall: 14px; */

    /*  sizes * 1.25*/
    /*     --xbs_XXBig: 43px;
    --xbs_xbig: 38px;
    --xbs_Big: 28px;
    --xbs_Medium: 26px;
    --xbs_Medium-Small: 24px;
    --xbs_Normal: 21px;
    --xbs_Small-Big: 19px;
    --xbs_Small: 16px;
    --xbs_XSmall: 14px; */

    /*  sizes * 1.15*/
    --xbs_XXBig: 45px;
    --xbs_XBig: 40px;
    --xbs_Big: 30px;
    --xbs_Medium: 27.5px;
    --xbs_Medium-Small: 25px;
    --xbs_Normal: 22.5px;
    --xbs_Small-Big: 20px;
    --xbs_Small: 17.5px;
    --xbs_XSmall: 13px; /* 15px is too big* /

    /*  sizes * 1.32*/
    /*  --xbs_XXBig: 47px;
    --xbs_XBig: 42px;
    --xbs_Big: 31px;
    --xbs_Medium: 29px;
    --xbs_Medium-Small: 26px;
    --xbs_Normal: 23px;
    --xbs_Small-Big: 21px;
    --xbs_Small: 18px;
    --xbs_XSmall: 15px; */

    /*  sizes * 1.35*/
    /*     --xbs_XXBig: 48px;
    --xbs_XBig: 43px;
    --xbs_Big: 32px;
    --xbs_Medium: 29px;
    --xbs_Medium-Small: 27px;
    --xbs_Normal: 24px;
    --xbs_Small-Big: 21px;
    --xbs_Small: 18px;
    --xbs_XSmall: 16px; */

    /*   --xbs_XXBig: 42px;
    --xbs_XBig: 32px;
    --xbs_Big: 2vw;
    --xbs_Medium: 1.8vw;
    --xbs_Medium-Small: 1.6vw;
    --xbs_Small-Big: 1.4vw;
    --xbs_Small: 1.2vw;
    --xbs_Normal: 1.4vw;
    --xbs_XSmall: 0.8vw; */

    --big-screen-query: 1400px;
    --small-screen-query: 411px;
}

.input_regular {
    border: 1px solid var(--light-gray);
    background: var(--base-gray);
    border-radius: var(--border-radius);
}

.table-item {
    background: var(--base-gray);
    border-radius: var(--border-radius);
    padding: 1% 0;
}

.scrollable {
    padding-right: 7%;
}

.mainContainerItem {
    background: var(--white);
    border-radius: var(--border-radius);
}
.main-container-item {
    background: var(--white);
    border-radius: var(--border-radius);
}

.img-circle {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.grid-center {
    display: grid;
    align-items: center;
    justify-items: center;
}

.flex-col-align-center {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-col-align-center {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-col-align-space-start {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.flex-col-align-space-start_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
}

.flex-row-align-start_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}
.flex-row-align-end {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.flex-row-align-start_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}

.flex-col-align-space-between {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.flex-row-align-center {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-row-align-space-around {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.flex-row-align-space-between {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flex-row-align-space-between_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flex-row-align-space-around_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.flex-row-align-space-between_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flex-row-align-start {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.flex-row-align-center_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-row-align-center-start {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.flex-row-align-center-start_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.selects-container_row {
    padding: 2% 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.scrollable {
    padding-right: 7%;
}

.input_regular {
    border: 1px solid var(--light-gray);
    background: var(--base-gray);
    border-radius: var(--border-radius);
    font-size: var(--Small);
    padding: 1.7% 1%;
    transition: 0.2s ease-in;
}
.sidebar-company-img-container {
    height: 150px;
    width: 150px;
}
.sidebar-company-img {
    width: 100%;
    height: 100%;
}
